<template>
	<div class="bg_dark bg_intro_1">
		<div class="container">
			<div class="row">
				<div class="flex_wrap">
					<div class="intro_content maf001">
						<div class="intro_top character1">
							<div class="text_guide pt-20">
								<h4>{{  $language.auth.access_app_info }}</h4>
							</div>
							<div class="text_guide_desc pt-15">
								<p>{{  $language.auth.access_app_permission_require }}</p>
							</div>
						</div>
						<div class="intro_line">
							<span class="hide">{{ $language.agreement.dividingLine }}</span>
						</div>
						<div class="intro_bottom">
							<h4 class="confirm_info_tit">{{  $language.auth.access_permission_essential }}</h4>
							<div class="confirm_info">
								<ul class="confirm_list">
									<li>
										<span class="ico_picture"><em class="hide">{{  $language.common.picture }}</em></span>
										<p class="confirm_info_text">
											<strong>{{  $language.common.picture }}/{{  $language.common.media }}/{{  $language.common.file }}</strong>
											<em>{{  $language.image.image_cash_save }}</em>
										</p>
									</li>
									<li
										v-if="false"
									>
										<span class="ico_audio"><em class="hide">{{  $language.common.mike }}</em></span>
										<p class="confirm_info_text">
											<strong>{{  $language.common.mike }}</strong>
											<em>{{  $language.common.audio }}</em>
										</p>
									</li>
									<li>
										<span class="ico_camera"><em class="hide">{{  $language.common.camera }}</em></span>
										<p class="confirm_info_text">
											<strong>{{  $language.common.camera }}</strong>
											<em>{{  $language.common.take_qr }}</em>
										</p>
									</li>
								</ul>
							</div>
						</div>
					</div>
					<div class="intro_btn_wrap">
						<div class="btn_area">
							<button
								v-if="false"
								class="btn_l btn_fill_gray" @click="exit">{{  $language.common.cancel }}</button>
							<button class="btn_l btn_fill_blue" @click="setIntro">{{  $language.common.ok }}</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'mafia001'
		,data: function(){
			return {
				program: {
					name: this.$language.auth.access_app_info
					, not_header: true
					, not_footer: true
				}
			}
		}
		,methods: {
			setIntro: async function(){
				await localStorage.setItem('is_intro', 'y')
				this.$emit('to', { name: 'index'})
			}
			, setFile: function(e){
				console.log(e)
			}
			, exit: function(){
				try {
					console.log('mafia001 exitApp')
					this.$common.inAppWebviewCommunity('android', 'exitApp')
				}catch (e){
					console.log(e)
				}
			}
			, onIntro: function(){
				this.setIntro()
				this.$bus.$emit('onPermission')

				this.$bus.$on('confirmPermission', () => {
					this.setIntro()
				})
			}
		}
		,created() {
			this.$emit('onLoad', this.program)
			try{
				this.$common.inAppWebviewCommunity('android', 'is_app')
			}catch (e) {
				this.setIntro()
			}
		}
	}
</script>